@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: #dddddd75;
}

.spinner-container-bg {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #dddddd40;
  background-image: url('/src/Resources/Graphics/Images/map_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.spinner-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner-block img {
  width: 350px;
}

.text-block {
  margin-top: 16px;
  padding: 4px;
  background-color: #fff;
  border-radius: 4px;
}

.text-block p {
  margin: 0 2px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}

.text-block p:nth-child(2) {
  font-size: 14px;
}