.Attr-logo {
  display: inline-block;
  width: 66px;
  height: 14px;
  position: relative;
}

.Attr-logo img {
  width: 66px;
  height: 14px;
  position: absolute;
  top: 3px;
  left: 0;
}

.FirePoint-popup h1 {
  font-size: 18px;
  text-transform: capitalize;
  border-bottom: 2px solid lightgray;
}

.FirePoint-popup .FirePoint-popup__body p {
  margin: 2px 0;
}

.FirePoint-popup .FirePoint-popup__footer {
  margin-top: 8px;
  color: #ccc;
  font-size: 10px;
}

.FirePoint-popup .FirePoint-popup__link {
  display: flex;
  justify-content: center;
}

.FirePoint-popup .FirePoint-popup__link button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  background-color: #f84d43;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42857143;
  color: #fff;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-box-shadow: 0 2px 2px 0 rgb(194 66 66 / 14%), 0 3px 1px -2px rgb(194 66 66 / 20%), 0 1px 5px 0 rgb(194 66 66 / 12%);
  box-shadow: 0 2px 2px 0 rgb(194 66 66 / 14%), 0 3px 1px -2px rgb(194 66 66 / 20%), 0 1px 5px 0 rgb(194 66 66 / 12%);
}

.FirePoint-popup .FirePoint-popup__link button:hover {
  background-color: #000000;
  -webkit-box-shadow: 0 14px 26px -12px rgb(194 66 66 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(194 66 66 / 20%);
  box-shadow: 0 14px 26px -12px rgb(194 66 66 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(194 66 66 / 20%);
}

.Slide-overlay {
  z-index: 9999;
}

.App .MuiSnackbar-root {
  z-index: 10001;
}