body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiPickersPopper-root .MuiPickersLayout-root .MuiDateCalendar-root button.MuiButtonBase-root.MuiPickersDay-root,
.MuiPickersPopper-root .MuiPickersLayout-root .MuiDateCalendar-root button.MuiPickersYear-root,
.MuiPickersPopper-root .MuiPickersLayout-root .MuiDateCalendar-root button.MuiIconButton-root {
  box-shadow: none !important;
}

.MuiPickersPopper-root .MuiPickersLayout-root .MuiDateCalendar-root button.MuiButtonBase-root.MuiPickersDay-root:hover,
.MuiPickersPopper-root .MuiPickersLayout-root .MuiDateCalendar-root .MuiYearCalendar-root .MuiPickersYear-root button.MuiPickersYear-yearButton:hover {
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: rgba(177, 49, 50, 0.04) !important;
}

.MuiPickersPopper-root .MuiPickersLayout-root .MuiDateCalendar-root .MuiPickersYear-root button {
  padding: 1px 6px;
  box-shadow: none !important;
}