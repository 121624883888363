.WindyCam-item {
  font-size: 1.5rem;
}

.WindyCam-item span {
  color: #D7393E;
}

.WindyCam-video {
  margin-top: 12px;
}