
.inci-data {
  color: rgba(33, 33, 33, .87);
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.inci-data .incident-information.ibox .grid-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 2fr));
  gap: 1rem;
}

.inci-data .usa-table {
  font-size: 1.06rem;
  line-height: 1.5;
  border-collapse: collapse;
  border-spacing: 0;
  color: #1b1b1b;
  margin: 1.25rem 0;
  text-align: left;
}

.inci-data .incident-information.ibox .grid-container .usa-table {
  table-layout: fixed;
  width: 100%;
}

.inci-data .incident-information.ibox .grid-container .usa-table caption {
  font-size: 1.2rem;
  font-weight: 500;
  color: #1b1b1b;
}

.inci-data .incident-information.ibox .grid-container .usa-table tbody {
  width: 100%;
}

.inci-data .incident-information.ibox .grid-container .usa-table tbody tr th {
  width: 165px;
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  padding: 0.45rem 0.75rem
}

.inci-data .incident-information.ibox .grid-container .usa-table td,
.inci-data .incident-information.ibox .grid-container .usa-table th {
  border: 1px solid #f7f7f7;
}

.inci-data .usa-table--striped tbody tr:nth-child(odd) td,
.inci-data .usa-table--striped tbody tr:nth-child(odd) th {
  background-color: #f0f0f0;
  color: #1b1b1b;
}

.inci-data .incident-information.ibox .grid-container .usa-table tbody tr td {
  width: 100%;
  font-size: 16px;
}

.inci-data .usa-table--compact td,
.inci-data .usa-table--compact th {
  padding: 0.25rem 0.75rem;
}

@media (max-width: 699px){
  .inci-data .incident-information.ibox .grid-container .usa-table caption {
    width: fit-content;
  }
	.inci-data .incident-information.ibox .grid-container .usa-table tbody tr {
		display: grid;
	}
	.inci-data .incident-information.ibox .grid-container .usa-table tbody tr td {
		width: auto;
    border: none;
	}
}

.inci-data .unit-wrpper-info {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
  display: flex;
}

.inci-data .margin-right-3px {
  margin-right: -3px;
  display: inline;
}

.inci-data .units-main-fields-wrapper {
  padding: 0 1em 0 0;
}

.inci-data .units-main-fields-wrapper .unit-field-item {
  margin-bottom: 0!important;
}

.inci-data .units-city-state-zip .unit-field-item {
  display: inline;
}

.inci-data .units-main-fields-wrapper .units-city-state-zip {
  display: inline-table;
}

.inci-data .incident-main-info--unit-info .agency-logo,
.inci-data .incident-main-info--unit-agency .unit-field-item,
.inci-data .agency-logo h2,
.inci-data .field--name-field-agency-abbreviation,
.inci-data .field--name-field-agency-logo .title,
.inci-data .usa-accordion__heading,
.inci-data .usa-social-link {
  display: none;
}

.inci-data .incident-main-info--unit-agency .agency-logo {
  display: block;
}

.inci-data .incident-main-info--unit-agency .agency-logo img {
  display: block;
  max-width: 50px;
  margin: 0 auto;
  height: auto;
}

.inci-data .title,
.inci-data header>h2,
.inci-data h4 {
  color: #D7393E;
}

.inci-data .title {
  margin: 0;
}

.inci-data h4 {
  font-size: 1.5rem;
}

.inci-data .media-right {
  display: none;
}

.incident-photo {
	float: none;
	margin: 10px 0;
}

.inci-data .incident-photo img {
	padding-bottom: 5px;
}

.inci-data .incident-photo small {
	display: block;
	text-align: left;
}

.inci-data .incident-overview-image-container {
  display: none;
}

.inci-data .usa-modal {
  padding: 0 0 1em 1em;
  float: right;
}

.inci-data .usa-modal img {
  max-width: 300px;
  height: auto;
}

.inci-data .usa-modal .usa-button.usa-modal__close {
  display: none;
}

@media (max-width: 480px){
	.inci-data .incident-photo {
		width: 100%;
		text-align: left;
	}

  .inci-data .usa-modal {
    padding: 0;
    float: none;
  }

  .inci-data .usa-modal img {
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 480px){
	.inci-data .incident-photo {
		float: right;
		margin:10px 0 10px 10px;
		max-width: 100%;
	}
}

.inci-data .text-muted {
  color: #595959;
}

.inci-data .feed-articles a {
  display: block;
}

.inci-data .feed-headline {
  font-weight: 600;
}
.inci-data a {
  color: #D7393E;
  text-decoration: none;
}

.inci-data a:hover,
.inci-data a:active {
  color: #C24242;
  text-decoration: underline;
}

.inci-data .feed-element {
  padding: 15px;
  border-top: 1px solid #ededed;
}

.inci-data .related-links .ibox a {
  display: block;
  margin-bottom: 0.6em;
}

.inci-data .cooperators a {
  display: block;
  margin-bottom: 0.6em;
}

.inci-data .incident-article .views-view-grid .article-item-row .article-item-column {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
}

.inci-data .incident-article-item {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 1.4em;
  padding-bottom: 1em;
}

.inci-data .incident-article-item .incident-publication-item-wrapper .incident-publication-title-and-info h5 {
  margin: 0 0 1.5em 0;
  font-weight: 400;
}

.inci-data .MuiBox-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
  color: #e91e63;
  background-color: transparent;
  box-shadow: none;
}

.inci-data .MuiTabs-indicator {
  background-color: #e91e63;
}

.inci-data .inci-data--footer {
  padding: 12px 0;
  text-align: center;
  background-color: #f0f0f0;
}